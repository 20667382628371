(function ($) {

    $(function () {

        var filterData = {};

        $( '.gallery-filter-button').click(function() {

            var gallery_module_id = $(this).parent().parent().parent().parent().attr('data');
            var type = $(this).val();

            $('#gallery-images-default-' + gallery_module_id).hide();
            $('.gallery-filter-button').removeClass( 'gallery-category-active');
            $(this).addClass( 'gallery-category-active' );

            if( typeof type !== 'undefined' ) {
                filterData['type'] = type;
            }

            if( typeof type !== 'undefined' ) {
                filterData['gallery_module_id'] = gallery_module_id;
            }

            gallery_ajax_get ( type , gallery_module_id, filterData);

        });


        $( '#room-filter-submit' ).click(function(){

            $('.ajax-loading').css("display", "flex");

            var type = $("input[name=\"room-filer-button--layout\"]:checked, #room-type-filter").val(),
                attendees = $("input[name=\"room-filer-button--attendees\"]:checked, #room-attendees-filter").val();

          //  alert(type + attendees);

             //var type = $("#room-type-filter").val(),
             //   attendees = $("#room-attendees-filter").val();

            // alert('T radio' + type_radio + '<br>' + 'A radio' + attendees_radio + 'S type' + type + 'S At' + attendees);

            if( typeof type !== 'undefined' ) {
                filterData['type'] = type;
            }

            if( typeof attendees !== 'undefined' ) {
                filterData['attendees'] = attendees;
            }

            room_ajax_get (type, attendees, filterData );

        });

       /* $( '#room-filter-all' ).click(function(){

            $('#response-call').removeClass( 'show' );
            $('#room-listing-container').show();
            $('#response-call').hide();

            jQuery('.room-navigation select').val('');


        }); */

        function gallery_ajax_get( type, gallery_module_id, filterData) {

            var gallery_id = gallery_module_id;

            $.ajax({
                type: 'POST',
                url: ajax_path.ajaxurl,
                security: ajax_path.ajaxnonce,
                data: {"action": 'load-gallery', type: type, gallery_module_id: gallery_module_id, filterData: filterData },
                success: function(response) {
                    $('#response-call-' + gallery_id).removeClass( 'show' );
                    $('#ajax-target-' + gallery_id).html(response);
                    $('#response-call-' + gallery_id).show();
                    $('#gallery-loading-' + gallery_id).css("display", "flex");

                    setTimeout(function() {
                        $('#gallery-loading-' + gallery_id).css("display", "none");
                    }, 500);

                    return false;
                }
            });
        }

        function room_ajax_get( type, attendees, filterData) {

            $.ajax({
                type: 'POST',
                url: ajax_path.ajaxurl,
                security: ajax_path.ajaxnonce,
                data: {"action": 'load-room', type: type, attendees: attendees, filterData: filterData },
                success: function(response) {

                  //  alert(attendees + ' ' + type);
                    $('#response-call').removeClass( 'show' ).show();
                    $('#ajax-target').html(response);
                    $('#room-listing-container').hide();

                    setTimeout(function(){
                        $('.ajax-loading').css("display", "none");
                        $( '#response-call' ).addClass( 'show' );
                    }, 500);

                    return false;
                }
            });
        }


    });

})(jQuery, this);